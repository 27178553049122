import { createContext, useState, useEffect } from "react";
import { firebase } from "@project/shared";

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: any;
  setUser: any;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null as firebase.User | null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(null as boolean | null);
  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      setUser(user);
      setAuthenticated(user !== null);
    });
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated,
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
